import styled from 'styled-components'
import { getPrimaryVariant } from 'resources'
import { Input, Form, Tabs as AntdTabs } from 'antd'
import React from 'react'

export const Wrapper = styled.ul`
  margin: 0;
  padding: 0.5rem;
  min-height: 28rem;
  max-height: 28rem;
  overflow: auto;
`

export const Item = styled.li`
  list-style: none;
`

export const EmptyStateContainer = styled.div`
  min-height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextArea = styled(({ bgColor, ...rest }) => <Input.TextArea {...rest} />)`
  background: ${({ bgColor }) => bgColor || '#f4f4f4'};
  resize: none;
`

export const FormItem = styled(Form.Item)`
  &&& label {
    color: ${getPrimaryVariant('main')};
    font-weight: 600;
    font-size: 0.95rem;
    letter-spacing: -.05em;
  }
`

export const Tabs = styled(AntdTabs)`
  &&&&& {
    .ant-tabs-top-bar {
      border: none;
      margin-bottom: 1.5rem;
    }

    .ant-tabs-tab {
      margin-right: 0.85rem;
      border: solid 1px ${getPrimaryVariant('main')};
      border-radius: 1000px;
      padding: 0.5rem 0.85rem;
      color: ${getPrimaryVariant('main')};
    }

    .ant-tabs-tab-active {
      background: ${getPrimaryVariant('main')};
      color: #fff;
    }

    .ant-tabs-nav-scroll {
      padding: .25rem;
    }

    .ant-tabs-ink-bar {
      opacity: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
    }

  }
`
