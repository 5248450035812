import React from 'react'
import styled from 'styled-components'
import emptyStateDefault from 'assets/empty-state-default.svg'
import t from 'prop-types'

import { Empty, Typography } from 'antd'
import { spacing } from 'resources'

const EmptyState = ({
  imageSize = 100,
  variant = 'default',
  description = 'Nenhum dado para exibir',
  children,
  ...props
}) => (
  <EmptyContainer
    {...props}
    size={imageSize}
    image={variants[variant]}
    description={
      <TextContainer>
        <Title doesHaveContent={!!children} strong>
          {description}
        </Title>
        {children}
      </TextContainer>
    }
  />
)

const EmptyContainer = styled(Empty)`
  padding: 1.5rem 0;
  > .ant-empty-image {
    height: ${(p) => p.size}px;
  }
`

const TextContainer = styled.span`
  margin: ${spacing(2)} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
`

const Title = styled((props) => <Typography.Text {...props} />)`
  font-size: 18px;
  margin-bottom: ${(props) => (props.doesHaveContent ? '1rem' : '0')};
`

Title.propTypes = {
  doesHaveContent: t.bool,
}

const variants = {
  default: emptyStateDefault,
}

EmptyState.propTypes = {
  description: t.string,
  children: t.node,
  variant: t.string,
  imageSize: t.number,
}

export { EmptyState }
