import React from 'react'
import t from 'prop-types'
import { makeArray } from 'resources/composable'
import { EmptyState } from 'ui/empty-state'

import { openConfirmDialogStudyPlan } from 'resources'
import { EmptyStateContainer, Wrapper, FormItem, Item, TextArea, Tabs } from './components'

const StudyListSchedule = ({
  amountOfWeeks,
  disciplines = [],
  setFilter,
  setActiveTab,
  activeTab,
  toggleWaiting,
  isCreating,
  tempValues,
  handleTextAreaChange,
  warning,
  setWarning,
  onSubmit,
}) => {
  const weeks = makeArray(amountOfWeeks)

  if (isCreating) {
    setActiveTab('null')
  }

  const handleTabClick = (key) => {
    if (!warning) {
      setActiveTab(key)
      setFilter((prev) => {
        if (key == null || key === 'null') {
          const { disciplineId, ...rest } = prev
          return rest
        }
        return { ...prev, disciplineId: key }
      })
      toggleWaiting({ disciplinesAdmin: true })
      return
    }
    if (!isCreating) {
      openConfirmDialogStudyPlan({
        title: 'Aviso!',
        content: 'Houve alteração no conteúdo das semanas, necessário salvar a alteração antes de trocar de disciplina.',
        onConfirm: () => onSubmit(),
      })
    }
  }

  if (amountOfWeeks === 0) {
    return (
      <EmptyStateContainer>
        <EmptyState
          imageSize={90}
          description='Selecione as disciplinas e informe o número de semanas.'
        />
      </EmptyStateContainer>
    )
  }

  return (
    <Tabs animated={false} activeKey={activeTab} onTabClick={handleTabClick}>
      <Tabs.Pane tab='Geral'>
        {weeks.map((_, id) => (
          <Item key={id}>
            <FormItem name={`${id}-schedule`} label={`Semana ${id + 1}`}>
              <TextArea
                autoSize={{ minRows: 3, maxRows: 3 }}
                onChange={(e) => isCreating && handleTextAreaChange(e.target.value, id)}
              />
            </FormItem>
          </Item>
        ))}
      </Tabs.Pane>
      {!isCreating && disciplines.map(({ name, id: disciplineId }) => (
        <Tabs.Pane tab={name} key={disciplineId}>
          <Wrapper>
            {weeks.map((_, id) => (
              <Item key={id}>

                {!isCreating ? (
                  <FormItem name={`${id}-schedule`} label={`Semana ${id + 1}`}>
                    <TextArea autoSize={{ minRows: 3, maxRows: 3 }} onChange={() => setWarning(true)} />
                  </FormItem>
                ) : (
                  <FormItem name={`${disciplineId}-${id}-schedule`} label={`Semana ${id + 1}`}>
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      value={tempValues[disciplineId]?.[id] || ''}
                      onChange={(e) => handleTextAreaChange(e.target.value, id)}
                    />
                  </FormItem>
                )}
              </Item>
            ))}
          </Wrapper>
        </Tabs.Pane>
      )
      )}
    </Tabs>
  )
}

StudyListSchedule.propTypes = {
  amountOfWeeks: t.number.isRequired,
  disciplines: t.array,
  setFilter: t.func,
  setActiveTab: t.func,
  activeTab: t.bool,
  toggleWaiting: t.func,
  isCreating: t.bool,
  handleTextAreaChange: t.func,
  tempValues: t.object,
  setWarning: t.func,
  warning: t.bool,
  onSubmit: t.func,
}

export { StudyListSchedule }
